module.exports ={
  "propertyService": "https://lj6itmddi4.execute-api.us-east-1.amazonaws.com/bhaller",
  "domusoApiService": "https://pr3znntcx7.execute-api.us-east-1.amazonaws.com/bhaller",
  "appService": "https://domusograilsweb-bhaller.devmuso.com",
  "reservationService": "https://12ks4dma60.execute-api.us-east-1.amazonaws.com/bhaller",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://1mrxta30il.execute-api.us-east-1.amazonaws.com/bhaller",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://domusograilsweb-bhaller.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident-bhaller.devmuso.com",
  "maintenanceUrl": "https://1mrxta30il.execute-api.us-east-1.amazonaws.com/bhaller/maintenance",
  "env": "bhaller"
}